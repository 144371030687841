.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0.2em;
}

.font-light {
  font-size: 0.8em;
  font-weight: 200;
}

.font-middle {
  font-size: 1.5em;
  font-weight: 200;
}

.fa {
  padding: 10px;
  margin: 10px;
  font-size: 15px;
  width: 15px;
  height: 15px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

.fa:hover {
  opacity: 0.7;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-instagram {
  background: #125688;
  color: white;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;  /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;            /* 3 */
}